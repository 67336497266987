import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

const Layout = ({ children }) => {
  const [Preloaded, setPreloaded] = useState(true);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  /* preload hook */
  useEffect(() => {
    setTimeout(() => {
      setPreloaded(false);
    }, 0);
  }, []);

  return (
    <>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          {
            name: 'description',
            content: 'Peter Henson Valdes II personal website',
          },
          {
            name: 'keywords',
            content:
              'petervaldesii, peterhenson, petervaldes, peterv, gatsbyjs, reactjs, react, photographer, developer, creative',
          },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <div
        key="mainbody"
        className={Preloaded ? 'main-body is-preload' : 'main-body'}
      >
        {children}
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
/* 

class Layout extends Component {
  constructor() {
    super();
    this.state = {
      isPreloaded: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 50);
  }

  render() {
    const { children } = this.props;
    const { isPreloaded } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
           
            <div className={isPreloaded ? 'main-body is-preload' : 'main-body'}>
              {children}
            </div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
 */
